var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("Рекомендации / Согласование")]),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"fab":"","color":"success","disabled":_vm.disabled},on:{"click":_vm.addOrderImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-upload")])],1)]}}])},[_c('span',[_vm._v("Загрузить")])])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.table.headers,"items":_vm.item.images},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image!=null)?_c('v-img',{staticClass:"rounded my-1",attrs:{"src":_vm._f("imageUrl")(item.image,'medium', true),"max-height":"300","max-width":"300"}}):_c('div',{staticClass:"d-flex justify-space-around",staticStyle:{"width":"120px","height":"120px"}},[_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-ghost-outline")])],1)]}},{key:"item.isPublic",fn:function(ref){
var item = ref.item;
return [(item.isPublic)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-lock-open-variant-outline ")]):_c('v-icon',{attrs:{"color":"info"}},[_vm._v(" mdi-lock-outline ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.editOrderImage(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","text":"","small":""},on:{"click":function($event){return _vm.deleteOrderImage(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}])})],1),_c('my-dialog-upload-image',{attrs:{"on-submit":_vm.uploadOrderImage},model:{value:(_vm.uploadOrderImageDialog),callback:function ($$v) {_vm.uploadOrderImageDialog=$$v},expression:"uploadOrderImageDialog"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.deleteDialog.show),callback:function ($$v) {_vm.$set(_vm.deleteDialog, "show", $$v)},expression:"deleteDialog.show"}},[_c('v-card',[_c('v-card-title',{staticClass:"title error--text"},[_vm._v("Удаление данных")]),_c('v-card-text',{staticClass:"my-2"},[_c('div',{staticClass:"text-body-2"},[_vm._v(" Вы действительно хотите произвести удаление данных? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.deleteOrderImageConfirm}},[_vm._v(" Продолжить ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.deleteDialog.show = false}}},[_vm._v(" Отмена ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }