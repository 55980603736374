<template>
  <v-card>
    <v-card-title>Рекомендации / Согласование</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            color="success"
            :disabled="disabled"
            v-bind="attrs"
            class="mr-2"
            v-on="on"
            @click="addOrderImage"
          >
            <v-icon>mdi-upload</v-icon>
          </v-btn>
        </template>
        <span>Загрузить</span>
      </v-tooltip>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="table.headers"
        :items="item.images"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            v-if="item.image!=null"
            :src="item.image | imageUrl('medium', true)"
            max-height="300"
            max-width="300"
            class="rounded my-1"
          ></v-img>
          <div v-else style="width:120px;height:120px" class="d-flex justify-space-around">
            <v-icon x-large>mdi-ghost-outline</v-icon>
          </div>
        </template>
        <template v-slot:item.isPublic="{ item }">
          <v-icon v-if="item.isPublic" color="error">
            mdi-lock-open-variant-outline
          </v-icon>
          <v-icon v-else color="info">
            mdi-lock-outline
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            fab
            text
            small
            @click="editOrderImage(item)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            class="ml-1"
            @click="deleteOrderImage(item)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <my-dialog-upload-image v-model="uploadOrderImageDialog" :on-submit="uploadOrderImage"></my-dialog-upload-image>
    <v-dialog v-model="deleteDialog.show" persistent max-width="600px">
      <v-card>
        <v-card-title class="title error--text">Удаление данных</v-card-title>
        <v-card-text class="my-2">
          <div class="text-body-2">
            Вы действительно хотите произвести удаление данных?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="deleteOrderImageConfirm">
            Продолжить
          </v-btn>
          <v-btn text @click="deleteDialog.show = false">
            Отмена
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { imageUrl } from '@/utils/ServiceUrls'
import MyDialogUploadImage from '@/components/ui/dialog/MyDialogUploadImage.vue'
import ToastService from '@/service/ToastService'

export default {
  name: 'OrderImagesCard',
  components: { MyDialogUploadImage },
  props: {
    disabled: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data: function () {
    return {
      uploadOrderImageDialog: false,
      table: {
        headers: [
          { text: '', value: 'image', sortable: false, align: 'left' },
          { text: 'Видимость', value: 'isPublic', sortable: true, align: 'left' },
          { text: 'Заметки', value: 'notes', sortable: false },
          { text: '', value: 'actions', sortable: false, align: 'right' }
        ]
      },
      deleteDialog: {
        show: false,
        value: []
      }
    }
  },
  computed: {
    ...mapState('order', ['item','busy'])
  },
  methods: {
    ...mapActions('order', ['uploadImage', 'deleteImages']),
    addOrderImage() {
      this.uploadOrderImageDialog = true
    },
    uploadOrderImage(blobImage) {
      if (blobImage.size > 20000000) {
        ToastService.error('Выбранная область более 20Mb, выберите меньше')
        throw Error()
      }
      this.uploadImage({ file:blobImage })
        .then(() => {
          ToastService.success('Картинка закачана')
          this.uploadOrderImageDialog = false
        })
        .catch((error) => {
          console.warn(error)
          ToastService.error('Что-то пошло не так')
        })
    },
    editOrderImage(orderImage) {
      this.$router.push({ name: 'order.image.edit', params: { id: this.item.id, imageId: orderImage.id } })
    },
    deleteOrderImage(image) {
      this.deleteDialog.show = true
      this.deleteDialog.value = image.id
    },
    deleteOrderImageConfirm() {
      this.deleteImages({
        orderImageIds: [this.deleteDialog.value]
      }).then((result) => {
        ToastService.success('Картинки удалены')
      }).catch((error) => {
        ToastService.error('Что-то пошло не так!')
        console.log(error)
      }).finally(() => {
        this.deleteDialog.show = false
        this.deleteDialog.value = null
      })
    }
  }
}
</script>
